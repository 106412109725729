import React from 'react'
import Layout from '../components/layout'
import HeaderSubsite from '../components/headersubsite'
import ConsultingAppointment from '../components/consultingappointment'
import Footer from '../components/footer'
import SubFooter from '../components/subfooter'
import ImageText from '../components/imagetext'

import BackgroundImageTwo from '../images/shutterstock_1090445729.jpg'
import Placeholder from '../images/mario2.jpg'
import Beratung2 from '../images/steuerberatung-srd.jpg'

import Karriere1 from '../images/karriere1.jpg'
import Karriere2 from '../images/karriere2.jpg'

const KarrierePage = () => (
  <Layout>
    <HeaderSubsite
      headline="Karriere"
      subline="Ihre Zukunft liegt uns am Herzen"
      bg={Beratung2}
    />
    <ImageText
      bg={Karriere1}
      subline="Karriere"
      headline="Aktuelle Stellenangebote"
    >
      <p>
        <strong>
          Steuerfachangestellte/n, Steuerfachwirt/in, Bilanzbuchhalter/in in
          Vollzeit
        </strong>
        <br />
        Wir suchen für unseren Standort in München (Schwabing) eine/n
        engagierte/n Steuerfachangestellte/n, Steuerfachwirt/in,
        Bilanzbuchhalter/in in Vollzeit, insbesondere zur Erstellung von
        Buchführung, Jahresabschlüssen, Steuererklärungen und Löhne sowie eine/n
        Steuerauszubildende/n.
        <br />
        Wir bieten eigenverantwortliches und kreatives Arbeiten im
        partnerschaftlichen Team.
        <br />
        <br />
        <strong>Steuerberater/-in</strong>
        <br />
        Sie sind offen gegenüber Neuem und wollen unser Team mit Ihrem
        Engagement noch erfolgreicher machen. Wir bieten ein angenehmes,
        modernes Arbeitsumfeld mit und ein gutes Betriebsklima. Ein motiviertes
        junges Team, das Sie gerne unterstützt. Attraktives Gehalt. Eine sichere
        und langfristige Arbeitsperspektive, sowie Aufstiegsmöglichkeiten als
        Partner.
        <br />
        <br />
        Bitte richten Sie Ihre Bewerbungsunterlagen inklusive einer
        Gehaltsvorstellung ausschließlich per Mail an:
        mr@steuerkanzlei-ruberto.de
      </p>
    </ImageText>
    <ConsultingAppointment
      title="Schön dass Sie sich für einen erstklassigen Job interessieren!"
      cta="Termin vereinbaren"
      bgcolor="#d3a04e"
      color="#fff"
    />
    <ImageText bg={Karriere2} subline="Karriere" headline="Wir bilden aus!">
      <p>
        <strong>
          Ausbildung als Steuerfachangestellter/e, Steuerfachwirt/in,
          Steuerberater/in
        </strong>
        <br />
        Sie sind begeistert von der Vielfalt des Steuersystems und sehen Ihre
        berufliche Zukunft in der Steuerberatung? Dann freuen wir uns darauf,
        Sie kennen zu lernen!
        <br />
        Qualifizierten Bewerbern geben wir in unserer Kanzlei die Möglichkeit
        zur Ausbildung als Steuerfachangestellter/e, Steuerfachwirt/in,
        Steuerberater/in.
        <br />
        Freuen Sie sich auf ein junges, dynamisches Team, einen modernen
        Arbeitsplatz in bevorzugter Münchner Lage sowie auf eine
        leistungsgerechte Vergütung. Ein angenehmes Betriebsklima, das von
        Offenheit, Fairness und flachen Hierarchien geprägt ist, erleichtert
        Ihnen den Start.
        <br />
        Der Umgang und Einsatz aller/der gängigen DATEV- und
        MS-Office-Software-Pakete ist bei uns ebenso selbstverständlich wie die
        gezielte Förderung unserer Mitarbeiterinnen und Mitarbeiter durch
        kontinuierliche Weiterbildungs- und Fortbildungsmaßnahmen.
        <br />
        Sie arbeiten nicht nur gern mit Zahlen, sondern auch mit Menschen? Ihr
        Denken und Handeln ist geprägt von einem hohen Maß an Zuverlässigkeit
        und Gewissenhaftigkeit? Ihre Arbeitsweise ist vorausschauend? Ihr
        Studienschwerpunkt liegt oder lag in den Fachbereichen
        Betriebswirtschaft oder Jura?
      </p>
    </ImageText>

    <Footer />
    <SubFooter />
  </Layout>
)

export default KarrierePage
