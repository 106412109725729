import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1280px;
  padding: 1.45rem 1.0875rem 0;
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  flex-direction: ${props => props.direction};
`

const LeftCol = styled.div`
  width: 50%;
  background-color: #fff;
  padding: 40px;

  @media only screen and (max-width: 1000px) {
    width: 100%;
    padding-left: 10px;
  }
`

const RightCol = styled.div`
  width: 50%;
  background: url(${props => props.bg}) no-repeat center center;
  background-size: cover;
  margin-bottom: 30px;
  padding-right: 30px;
  @media only screen and (max-width: 1000px) {
    height: 200px;
    width: 100%;
  }
`

const Headline = styled.h1`
  font-family: 'Roboto';
  font-size: 1.85rem;
  color: #333;
  line-height: 1.85rem;
`

const Subline = styled.h3`
  font-family: 'Open Sans';
  font-size: 1.25rem;
  font-weight: 600;
  color: #d3a04e;
  line-height: 1.25rem;
`

const Copytext = styled.p`
  color: #898989;
  font-family: 'Open Sans';
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
`

export default function ImageText(props) {
  const { subline, headline, children } = props
  return (
    <Wrapper direction={props.direction}>
      <LeftCol>
        <Subline>{subline}</Subline>
        <Headline>{headline}</Headline>
        <Copytext>{children}</Copytext>
      </LeftCol>
      <RightCol bg={props.bg} />
    </Wrapper>
  )
}
