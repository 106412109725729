import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
//import GlobalInnerWrapper from '../globalinnerwrapper'

const ConsultingWrapper = styled.div`
  background-color: ${props => props.bgcolor};
  margin-top: 40px;
`

const ConsultingInnerWrapper = styled.div`
  margin: 0 auto;
  max-width: 1280px;
  padding: 1.45rem 1.0875rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
`

const Headline = styled.h1`
  color: ${props => props.color};
  font-family: 'Open Sans';
  font-size: 30px;
  font-weight: 700;
  line-height: 30px;
  margin: 0;
  padding-bottom: 20px;
`

const Call2Action = styled.button`
  width: 228px;
  height: 50px;
  background-color: ${props => props.bgctacolor};
  border: 0;
`

const CTAText = styled.div`
  color: #323232;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
`

export default function ConsultingAppointment(props) {
  const { title, cta, color, bgcolor, bgctacolor } = props
  return (
    <ConsultingWrapper bgcolor={bgcolor}>
      <ConsultingInnerWrapper>
        <Headline color={color}>{title}</Headline>
        <Link to="/kontakt">
          <Call2Action bgctacolor={bgctacolor}>
            <CTAText>{cta}</CTAText>
          </Call2Action>
        </Link>
      </ConsultingInnerWrapper>
    </ConsultingWrapper>
  )
}
